import React, {useState} from 'react'
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import uyelik from "../images/register.svg"
import queryString from "query-string"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"


import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"

const RegisterPage = ({ location }) => {



const queryParams = queryString.parse(location.search)
let referralCode=queryParams.code
if(!referralCode){
    referralCode=""
}
const [formState, setFormState] = useState({firstName:"",lastName:"",phone:"",password:"",email:"",referralCode:referralCode})
const [changed, setChanged] = useState(false)
const [errorState, setError] = useState(false)




const handleChange = (e) => {
  setFormState({ ...formState, [e.target.name]: e.target.value })
}

console.log(formState);


const handleSubmit = (e) => {
  e.preventDefault()

  fetch('https://api.cember.app/api/user/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formState)
  })
  .then((res) => {
    console.log(res)
    if(res.status==='201'){
        setChanged(true)
        setError(false)
    }else{
        setChanged(false)
        setError(true)
    }
    
  })
  .catch((error) => alert(error))
}



    if(changed){
      return (
        <Layout>
        <Head title="Üyelik" />
        <div className="row register">
         <div className="hero-container">
         <div className="container">
           <div className="col-12">
            <div className="col-6">
            
              <h1>Your account has been created successfully! </h1>    
              <p>Download Çember now and login to start saving with your friends.</p>
              <div className="col-9">
              <div className="download-buttons">
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "ios",
                      action: "click",
                      label: "register"
                    })
                  }} href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "android",
                      action: "click",
                      label: "register"
                    })
                  }}  href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
              </div>
              </div>

            </div>
            <div className="col-6 register-visual">
              <img src={uyelik} alt="Register" />
  
            </div>
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }else{
      let errorMessage=""
      if(errorState){
        errorMessage ="Something went wrong. Please try again."
      }
      
      return (
        <Layout>
        <Head title="Register" />
        <div className="row register">
         <div className="hero-container">
         <div className="container">
            <div className="col-6">
            
              <h1>Register</h1>   
              <p>{errorMessage}</p>
              <div className="col-12">
                <form onSubmit={handleSubmit}  name="uyelik"  method="post" >
                  <input required type="text" name="firstName" placeholder="First Name" onChange={handleChange} />  
                  <input required type="text" name="lastName" placeholder="Last Name" onChange={handleChange} />  
                  <input required type="email" name="email" placeholder="E-mail Address" onChange={handleChange} />  
                  <input required type="phone" name="phone" placeholder="Phone Number" onChange={handleChange} />  
                  <input required type="password" name="password" placeholder="Password" onChange={handleChange} />  
                
                  <button type="submit" >Register</button>
                </form>
              </div>
             
            </div>
            <div className="col-6 register-visual">
            <img src={uyelik} alt="Register" />
  
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }
    
}

export default RegisterPage